import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    // Get the user agent to determine the platform
    const userAgent = navigator.userAgent.toLowerCase();

    // Define the iOS and Android app store URLs
    const iosAppStoreURL = 'https://apps.apple.com/app/yumdealz/id1602929962';
    const androidAppStoreURL = 'https://play.google.com/store/apps/details?id=io.mealdealz.vproductions';

    // Check if the user agent indicates iOS
    if (userAgent.match(/(iphone|ipod|ipad)/)) {
      // Redirect to iOS app store
      window.location.href = iosAppStoreURL;
    }
    // Check if the user agent indicates Android
    else if (userAgent.match(/android/)) {
      // Redirect to Android app store
      window.location.href = androidAppStoreURL;
    }
    // Default to a message if platform cannot be determined
    else {
      const messageElement = document.createElement('p');
      messageElement.innerText = 'Unsupported platform.';
      document.body.appendChild(messageElement);
    }
  }
}
